import React from "react";
import { TableCell, TableRow } from "@aws-amplify/ui-react";

export default class School extends React.Component {
    render() {
        const school = this.props.school;
        const name = school.name;
        const kommune = school.kommune;
    
        return (
            <TableRow>
                <TableCell>{name}</TableCell>
                <TableCell>{kommune}</TableCell>
            </TableRow>
        );
    }
}