// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { PropertyList, Property, Comment, Kommune, SkoleDistrikt } = initSchema(schema);

export {
  PropertyList,
  Property,
  Comment,
  Kommune,
  SkoleDistrikt
};