import React, { Component } from "react";
import "./App.css";
import { withAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import { DataStore } from '@aws-amplify/datastore';
import { PropertyList } from './models';
import '@aws-amplify/ui-react/styles.css';
import SchoolList from './partials/schoolList';
import schoolsJSON from './resources/schools.json'

class App extends Component {
  createMutation = async () => {
    const newPropertyList = await DataStore.save(
        new PropertyList({
        "name": "Lorem ipsum dolor sit amet"
      })
    );
    alert(JSON.stringify(newPropertyList));
  };

  listMutation = async () => {
    const models = await DataStore.query(PropertyList);
    alert(JSON.stringify(models));
  };

  render() {
    const SCHOOLS = schoolsJSON;

    return (
      <div className="App">
        <Authenticator>
        {({ signOut, user }) => (
          <div className="App">
            <p>
              Hey {user.username}, nice to see you.
            </p>
            <button onClick={signOut}>Sign out</button>
          </div>
        )}
        </Authenticator>
        
        <h1>Schools</h1>
        <SchoolList schools={SCHOOLS}></SchoolList>

        <h1>Property management</h1>
        <p> Click a button </p>
        <button onClick={this.createMutation}>Add property</button>
        <button onClick={this.listMutation}>List properties</button>
      </div>
    );
  }
}

export default withAuthenticator(App, true);
