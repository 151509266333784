import {
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
  } from '@aws-amplify/ui-react';
import React from "react";
import School from "./school";

export default class SchoolList extends React.Component {
    render() {
        const rows = [];

        this.props.schools.forEach((school) => {
            rows.push(
                <School
                school={school}
                key={school.name}
                />
            );
        });
        
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell as="th">Name</TableCell>
                        <TableCell as="th">Kommune</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
        );
    }
}